<template>
  <div
    v-if="!section.deleted"
    class="module-section-container"
  >
    <div
      :id="'Section' + '-' + section.id"
      class="module-content-box"
    >
      <order-box
        :order-object="section"
        order-object-type="Section"
        :order-object-id="section.id"
        :index="index"
        :anchor-key="&quot;section-&quot; + index"
        :draft-mode="draftMode"
        :is-draggable="false"
        :group-size="sectionsLength"
        :box-styles="boxStyles"
        delete-modal-name="delete-section-modal"
        @move="moveSection"
      >
        <template v-slot:headerTitle>
          Section {{ section.id }}
        </template>

        <template v-slot:content>
          <div class="module-content-section">
            <div class="section-title section-area">
              <label class="module-label weight-600">Section Title</label>
              <input
                v-model="section.title"
                class="module-input hlt-input"
                type="text"
                required
                :class="{'error': errors && errors['name'] }"
                placeholder="Write section title"
                :disabled="!draftMode"
              >
            </div>
            <div class="section-description section-area">
              <div
                class="module-section-container description-area"
                @click="e => moduleToggle(e, 'sectionDescription', 'section-description')"
              >
                <div
                  v-if="!editing['sectionDescription']"
                  class="module-edit-html-description"
                >
                  <label
                    class="container-label weight-600"
                    for="sec-description"
                  >Section Description</label>
                  <div
                    :class="[{ 'empty-content': emptyContent(section.description), 'disabled': !draftMode }, 'container-textarea']"
                    name="sec-description"
                    v-html="section.description || 'Write section description'"
                  />
                </div>

                <div
                  v-else
                  class="module-edit-active description-active"
                >
                  <label
                    class="container-label weight-600"
                    for="description"
                  >Section Description</label>
                  <tinymce-editor
                    ref="section-description"
                    v-model="section.description"
                    v-on-clickaway="e => moduleToggle(e, 'sectionDescription', 'section-description')"
                    class="module-tinymce"
                    :init="tinymceConfig({plugins: ['code', 'autoresize', 'table', 'lists','image'], forced_root_block : ''}, tinymceFullConfig)"
                    name="title"
                    @onInit="setCaretPosition"
                  />
                </div>
              </div>
            </div>
            <div class="section-completion section-area">
              <div
                class="module-section-container completion-area"
                @click="e => moduleToggle(e, 'sectionCompletion', 'section-description')"
              >
                <div
                  v-if="!editing['sectionCompletion']"
                  class="module-edit-html-completion"
                >
                  <label
                    class="container-label weight-600"
                    for="sec-description"
                  >Section Completion HTML</label>
                  <div
                    :class="[{ 'empty-content': emptyContent(section.completion_page_html), 'disabled': !draftMode }, 'container-textarea']"
                    name="sec-description"
                    v-html="section.completion_page_html || 'Write section conclusion'"
                  />
                </div>

                <div
                  v-if="editing['sectionCompletion']"
                  class="module-edit-active completion-active"
                >
                  <label
                    class="container-label weight-600"
                    for="description"
                  >Section Completion HTML</label>
                  <tinymce-editor
                    ref="section-completion"
                    v-model="section.completion_page_html"
                    v-on-clickaway="e => moduleToggle(e, 'sectionCompletion', 'section-completion')"
                    class="module-tinymce"
                    :init="tinymceConfig({plugins: ['code', 'autoresize', 'table', 'lists','image'], forced_root_block : ''}, tinymceFullConfig)"
                    name="title"
                    @onInit="setCaretPosition"
                  />
                </div>
              </div>
            </div>
            <div class="add-asset-section">
              <label
                v-if="section.asset_associations.length > 0"
                for=""
                class="container-label weight-600"
              >
                Section Contents
              </label>
              <section-assets
                :anchor-prefix="&quot;section-&quot; + index + &quot;-&quot;"
                :asset-associations="section.asset_associations"
                :draft-mode="draftMode"
                :section-id="section.id"
                :asset-type="'learning_module'"
              />
              <add-asset
                v-if="draftMode"
                :section-id="section.id"
                :asset-a-type="'ModuleSection'"
                :asset-type="'learning_module'"
                @saveDisabled="saveToParent"
              />
            </div>
          </div>
        </template>
      </order-box>
    </div>
  </div>
</template>

<script>
import AddAsset from '../shared/asset_components/AddAsset'
import SectionAssets from '../shared/asset_components/SectionAssets'
import TinymceEditor from '@tinymce/tinymce-vue'
import tinymce from '../../../mixins/tinymce'
import OrderBox from '../../shared/OrderBox'
import { mixin as clickaway } from 'vue-clickaway2'
import { mapGetters } from 'vuex'

export default {
  components: {
    AddAsset,
    SectionAssets,
    TinymceEditor,
    OrderBox
  },
  mixins: [
    clickaway,
    tinymce
  ],
  props: {
    section: {
      type: Object,
      required: true
    },
    index: {
      type: Number
    },
    assetAssociations: {
      type: Array,
      required: true
    },
    draftMode: {
      type: Boolean
    },
    sectionsLength: {
      type: Number
    }
  },
  data () {
    return {
      editing: {
        sectionCompletion: false,
        sectionDescription: false,
        sectionTitle: null
      },
      boxStyles: {
        header: ['shaded-background'],
        content: ['shaded-background']
      }
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'errors'
    ])
  },
  methods: {
    moveSection ({ index, section, direction }) {
      let emitObj = { index, section, direction }
      this.$emit('moveModuleSection', emitObj)
    },
    saveToParent (value) {
      this.$emit('saveDisabled', value)
    },
    moduleToggle (event, prop, reference) {
      if (this.draftMode) {
        if (!event) return
        if (event.target.className.includes('mce') || event.target.id.includes('mce')) {
          return
        }
        Object.keys(this.editing).forEach(key => {
          this.editing[key] = prop == key ? !this.editing[prop] : false
        })
        this.setCharPosition(event, reference)
      }
    },
    emptyContent (content) {
      return content === null || content === ''
    }
  }
}
</script>

<style lang="scss">
  .module-section-container {
    label {
      margin: 0;
    }
    .container-textarea {
      &.disabled {
        background: $grey-4;
      }
    }
  }
</style>
