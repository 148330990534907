<template>
  <div
    id="add-asset-box"
    class="asset-box"
  >
    <div class="header">
      <div class="left">
        <p class="module-title weight-700 add-asset-header montserrat">
          {{ title }}
        </p>
      </div>
    </div>
    <div class="content">
      <div
        v-if="!addingAssetSpinner"
        class="add-asset-row"
      >
        <div class="asset-select">
          <label
            for="asset-type"
            class="module-label weight-600"
          >Asset Type</label>
          <select
            v-model="assetBType"
            class="hlt-select asset-b-type"
            name="asset-type"
          >
            <option value="null">
              Select Asset Type
            </option>
            <option
              v-for="(asset, i) in assetSelectTypes"
              :key="i"
              :value="asset.value"
            >
              {{ asset.name }}
            </option>
          </select>
        </div>
        <div class="asset-input">
          <label
            for="asset-type"
            class="module-label weight-600"
          >Asset ID</label>
          <input
            v-model="assetBId"
            type="text"
            name="asset-type"
            placeholder="Add Asset ID"
            :class="[{'error': errors['asset_b'] },'asset-b-input']"
            class="hlt-input asset-b-id"
          >
          <error-message
            v-if="errorAsset"
            :message="prettyErrors"
          />
        </div>
        <div class="asset-submit">
          <button
            class="hlt-button primary add-aa-button"
            :class="{'disabled': !validAsset }"
            :disabled="!validAsset"
            @click="addAsset()"
          >
            Submit
          </button>
        </div>
      </div>
      <div
        v-else
        class="loading-asset-section"
      >
        <div class="waiting-loader" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import railsRoutes from '@/store/rails-routes.js'
import { mapGetters, mapMutations } from 'vuex'

export default {
  props: {
    sectionId: {
      type: Number,
      required: false
    },
    assetAType: {
      type: String,
      required: true
    },
    showCategoryType: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: "Add a new asset"
    },
    displayType: {
      type: String
    }
  },
  data () {
    return {
      assetBId: null,
      assetBType: null,
      addingAssetSpinner: false,
      assetSelectTypes: []
    }
  },
  watch: {
    showCategoryType (val, oldVal) {
      this.resetAssetSelectTypes()
      if(val) {
        this.assetSelectTypes.push({
          value: 'CategoryType',
          name: 'Category Type'
        })
      }
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'errors'
    ]),
    assetA () {
      if (this.sectionId) {
        return this.asset.module_sections.filter(s => s.id === this.sectionId)[0]
      } else {
        return this.asset
      }
    },
    errorAsset () {
      return this.errors !== {} && this.errors.asset_b !== undefined
    },
    validAsset () {
      return this.assetBType !== null && this.assetBId !== null
    },
    prettyErrors () {
      if (this.errors.asset_b) {
        if (this.errors.asset_b.indexOf("can't be blank") >= 0) {
          return 'Invalid Asset ID'
        }
        if (this.errors.asset_b.indexOf('must be a live asset') >= 0) {
          return 'Must be a Live Asset ID'
        }
      }
      return 'Error'
    }
  },
  methods: {
    ...mapMutations([
      'setErrors',
      'resetErrors'
    ]),
    addAsset () {
      this.$emit('saveDisabled', true)
      this.addingAssetSpinner = true
      const assetAssociation = {
        asset_a_id: this.assetA.id,
        asset_a_type: this.assetAType,
        asset_b_id: parseInt(this.assetBId),
        asset_b_type: this.assetBType,
        display_type: this.displayType,
        order: this.assetA.asset_associations.length
      }
      axios.post(
        railsRoutes.api_path({ type: 'asset_associations', action: 'validate' }),
        { asset_association: assetAssociation }
      ).then(response => {
        this.assetA.asset_associations.push(response.data.asset_association)
        this.assetBType = ''
        this.assetBId = ''
        this.addingAssetSpinner = false
        this.$emit('saveDisabled', false)

        this.resetErrors()
      }).catch((err) => {
        this.saveDisabled = false
        this.$emit('saveDisabled', false)

        this.addingAssetSpinner = false
        this.setErrors({ errors: err.response.data.errors })
      })
    },
    resetAssetSelectTypes () {
      this.assetSelectTypes = [
        { value: 'V3Flashcard', name: 'Item' },
        { value: 'Topic', name: 'Topic' },
        { value: 'Attachment', name: 'Attachment' },
        { value: 'Grouping', name: 'Grouping' }
      ]
    }
  },
  mounted () {
    this.resetAssetSelectTypes()
  }
}
</script>

<style lang="scss">
.asset-box {
  background: $white-2;
  label {
    font-size: .875rem;
  }
  .header {
    padding: 10px;
    display: flex;
    border: 1px solid $space-gray;
    border-bottom: 0;
  }
  .add-asset-header {
    font-size: .875rem;
    margin: 0;
    align-self: center;
  }
  .content {
    border: 1px solid $space-gray;
    background: $white-2
  }
}
.add-asset-row, .loading-asset-section {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-around;
  align-items: stretch;
  width: 100%;
  padding: .75rem 5rem;
  .asset-select {
    flex-grow: 1;
    margin-right: 1.5rem;
  }
  .module-label {
    font-size: 12.8px;
    margin-bottom: 4px;
  }
  .asset-input {
    flex-grow: 1;
    margin-right: 1.5rem;
    padding-bottom: 10px;
  }
  .asset-submit {
    display: flex;
    align-items: center;
    margin-top: 20px;
    button {
      margin-bottom: .6rem;
      &:disabled {
        background: $green-1;
      }
    }
  }
}
</style>
