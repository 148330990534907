<template>
  <div class="groupings-edit-container">
    <div class="groupings-edit-content">
      <asset-description :asset="asset" :editing="editingAssetDescription" @set-editing="setEditingAssetDescription"/>

      <div class="main-container">
        <div class="grouping-name-section">
          <label class="grouping-label">Grouping Name</label>
          <input
            v-model="asset.name"
            class="grouping-input"
            type="text"
            :class="{'error': errors['name'] }"
          >
          <error-message
            v-if="errors[&quot;name&quot;]"
            message="can't be blank"
          />
        </div>
        <div class="dividing-section">
          <div class="divider hr">
            <hr class="grouping-hr">
          </div>
          <div
            v-if="draftMode"
            class="divider order-switch"
          >
            <div class="order-section">
              <hlt-toggle
                class="randomize-order-button"
                :value="randomizedAssetAssociations"
                style-type="medium"
                :font-size="11"
                :labels="{
                  checked: 'Randomize',
                  unchecked: 'Randomize'
                }"
                sync
                @change="randomizeAssets()"
              />
            </div>
          </div>
        </div>
        <div class="add-asset-section">
          <div class="main-asset-section">
            <div
              v-if="asset.grouping_type == 'Scenario Box'"
              class="scenario-box-container"
            >
              <div v-if="scenarioBox" class="scenario-box-details">
                <h5>Scenario Box</h5>
                <order-box
                  :order-object="scenarioBox.asset_b_data"
                  :order-object-type="displayAssetTypeName(scenarioBox.asset_b_type)"
                  :order-object-id="scenarioBox.asset_b_id"
                  anchor-key="scenario-box"
                  :draft-mode="draftMode"
                  :box-styles="boxStyles"
                  :isMovable="false"
                  :index="0"
                  group-name="scenario-box"
                  :open-asset-link="openAssetLink(scenarioBox)"
                  deleteModalName="delete-scenario-box-modal"
                >
                  <template v-slot:headerTitle>
                    {{ displayAssetTypeName(scenarioBox.asset_b_type) }} {{ scenarioBox.asset_b_id }}
                  </template>

                  <template v-slot:content>
                    &nbsp; <!-- This has to be here or the components dont get inserted -->
                    <item
                      v-if="scenarioBox.asset_b_type === 'V3Flashcard'"
                      :asset-data="scenarioBox.asset_b_data"
                    />
                    <grouping
                      v-if="scenarioBox.asset_b_type === 'Grouping'"
                      :asset-data="scenarioBox.asset_b_data"
                    />
                    <topic
                      v-if="scenarioBox.asset_b_type === 'Topic'"
                      :asset-data="scenarioBox.asset_b_data"
                    />
                    <attachment
                      v-if="scenarioBox.asset_b_type === 'Attachment'"
                      :asset-data="scenarioBox.asset_b_data"
                    />
                  </template>
                </order-box>
                <delete-order-box-modal
                  delete-modal-name="delete-scenario-box-modal"
                  @orderBoxDeletion="deleteScenarioBox"
                />

              </div>

              <add-asset
                v-show="!scenarioBox && draftMode"
                id="add-scenario-box"
                :asset-a-type="'Grouping'"
                :showCategoryType="showCategoryType"
                @saveDisabled="saveToParent"
                title="Add Scenario Box"
                displayType="scenario_box"
                class="add-scenario-box"
              />
            </div>
            <asset-association-summaries
              anchor-prefix="asset-association-"
              :asset-associations="sortedAssetAssociations"
              :draft-mode="draftMode"
              :section-id="asset.id"
              :asset-type="'grouping'"
            />
            <add-asset
              v-show="draftMode"
              id="add-asset-box"
              :asset-a-type="'Grouping'"
              :showCategoryType="showCategoryType"
              @saveDisabled="saveToParent"
            />

            <save-modal @save="overwrite" :asset="asset" v-if="asset.id != undefined" />
          </div>
          <div
            v-if="asset"
            class="asset-actions-section"
          >
            <label class="grouping-label">All Assets</label>
            <ul class="asset-side-list">
              <li
                v-for="(assetAssociation, i) in sortedAssetAssociations"
                :key="i"
                class="asset-listing"
              >
                <a
                  v-if="!assetAssociation.deleted"
                  v-smooth-scroll="{offset: -150}"
                  :href="'#asset-association-' + i"
                  class="asset-association"
                >
                  {{ displayAssetTypeName(assetAssociation.asset_b_type) }} {{ assetAssociation.asset_b_id }}
                </a>
              </li>
            </ul>
            <a
              v-show="draftMode"
              v-smooth-scroll="{offset: -150}"
              class="white-button"
              href="#add-asset-box"
            >Add New Asset</a>
          </div>
        </div>
      </div>
      <div class="association-section">
        <product-association
          v-if="currentUser.permissions.can_read_groupings_product_associations && asset.id && draftMode"
          :asset="asset"
          class="product-association-container"
        />
      </div>
      <metadata>
        <template v-slot:left>
          <div class="module-section section">
            <label
              for="grouping-type"
              class="grouping-label"
            >Grouping Type</label>
            <select
              v-model="asset.grouping_type"
              name="grouping-type"
              class="hlt-select select-grouping-type"
            >
              <option value="null">
                Select
              </option>
              <option>Blueprint</option>
              <option>Case Study</option>
              <option>Exhibit</option>
              <option>Item Cluster</option>
              <option>Progress</option>
              <option>Quiz</option>
              <option>Scenario Box</option>
              <option>Standard</option>
            </select>
          </div>
          <div class="module-section section">
            <label>Completion Time (minutes)</label>
            <input
              v-model="asset.completion_time"
              type="number"
              :disabled="!canWriteToAsset"
              class="hlt-input"
            >
          </div>
        </template>
        <template v-slot:right>
        </template>
      </metadata>
    </div>
    <edit-footer>
      <template v-slot:left>
        <div class="left">
          <button
            class="hlt-button default draft"
            :class="{ active : draftMode }"
            @click="toggleDraft(true)"
          >
            Draft
          </button>
          <button
            class="hlt-button default live"
            :class="{ active : !draftMode }"
            @click="toggleDraft(false)"
          >
            Live
          </button>
        </div>
      </template>
      <template v-slot:center>
        <div class="center">
          <div class="center-actions">
            <select
              v-model="asset.state"
              class="hlt-select"
            >
              <option>Select State</option>
              <option>New</option>
              <option>Review</option>
              <option>Edit</option>
              <option>Preflight</option>
              <option>Approved</option>
            </select>

            <button
              v-if="!saveDisabled"
              :class="[{ grey: (!draftMode || !canWriteToAsset) }, 'hlt-button', 'primary', 'save-grouping']"
              @click="canWriteToAsset && draftMode && save()"
            >
              Save Changes
            </button>
            <button
              v-if="saveDisabled"
              class="hlt-button primary save-disabled"
            >
              <spinner />
              Saving
            </button>
          </div>
        </div>
      </template>
      <template v-slot:right>
        <div class="right">
          <button
            :class="[{ grey: !draftMode || !canOverwriteLive }, 'hlt-button', 'secondary', 'overwrite']"
            @click="canWriteToAsset && canOverwriteLive && draftMode && overwriteLive()"
          >
            Overwrite Live
          </button>
        </div>
      </template>
    </edit-footer>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import axios from 'axios'

import railsRoutes from '@/store/rails-routes.js'
import AssetDescriptionMixin from '@/mixins/asset-description.js'

import Spinner from '../shared/Spinner.vue'
import ProductAssociation from '../shared/ProductAssociation.vue'
import AddAsset from '../shared/asset_components/AddAsset.vue'
import AssetAssociationSummaries from '../shared/asset_components/SectionAssets.vue'
import EditFooter from '../shared/page_components/EditFooter.vue'
import Metadata from '../shared/Metadata.vue'
import SaveModal from '../shared/SaveModal.vue'
import HltToggle from '../shared/page_components/Toggle.vue'
import AssetDescription from '../shared/AssetDescription.vue'
import OrderBox from '@/components/shared/OrderBox'

import Item from '../shared/asset_associations/Item'
import Grouping from '../shared/asset_associations/Grouping'
import Topic from '../shared/asset_associations/Topic'
import Attachment from '../shared/asset_associations/Attachment'
import DeleteOrderBoxModal from '@/components/shared/DeleteOrderBoxModal'

export default {
  name: 'Edit',
  components: {
    ProductAssociation,
    Spinner,
    AddAsset,
    AssetAssociationSummaries,
    EditFooter,
    Metadata,
    HltToggle,
    AssetDescription,
    SaveModal,
    OrderBox,
    Item,
    Grouping,
    Topic,
    Attachment,
    DeleteOrderBoxModal
  },
  mixins: [
    AssetDescriptionMixin
  ],
  props: ['groupingId'],
  data () {
    return {
      addingAssetSpinner: false,
      randomizedAssetAssociations: false,
      groupingAssetBType: '',
      groupingAssetBId: '',
      saveDisabled: false,
      draftMode: true,
      boxStyles: {
        header: [],
        content: ['height-restriction']
      }
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'deleteAsset',
      'currentUser',
      'canWriteToAsset',
      'errors'
    ]),
    scenarioBox () {
      return this.asset.asset_associations.filter(x => !x.deleted && x.display_type == 'scenario_box')[0]
    },
    canOverwriteLive () {
      return this.currentUser.permissions.can_overwrite_live_groupings
    },
    sortedAssetAssociations () {
      return this._.sortBy(this.asset.asset_associations.filter(x => x.display_type != 'scenario_box'), ['order'])
    },

    showCategoryType () {
      return this.asset.grouping_type == 'Progress'
    }
  },
  created () {
    this.setAssetType({ assetType: 'grouping' })
    this.apiGroupingsById({ groupingId: this.groupingId }).then(response => {
      const record = response.data.records[0]
      this.edit(record)
      this.draftMode = !this.asset.live_layer
    })
  },
  methods: {
    ...mapActions([
      'edit',
      'delete',
      'update',
      'apiGroupingsById'
    ]),
    ...mapMutations([
      'setAsset',
      'setAssetType',
      'setDeleteAsset',
      'setDeletableAsset',
      'resetDeletableAsset',
      'setErrors',
      'resetErrors',
      'resetAsset'
    ]),
    deleteScenarioBox () {
      this.scenarioBox.deleted = true
    },
    saveToParent (value) {
      this.saveDisabled = value
    },
    openAssetLink (assetAssociation) {
      return { name: this.routeAssetTypeName(assetAssociation.asset_b_type), params: { id: assetAssociation.asset_b_id } }
    },
    routeAssetTypeName (assetBType) {
      if (assetBType === 'V3Flashcard') {
        return 'item'
      } else if (assetBType === 'Grouping') {
        return 'grouping'
      } else if (assetBType === 'Topic') {
        return 'topic'
      } else if (assetBType === 'Attachment') {
        return 'attachment'
      } else {
        return assetBType
      }
    },
    randomizeAssets () {
      this.randomizedAssetAssociations = !this.randomizedAssetAssociations
      this.asset.asset_associations = this.orderedAssetAssociations(this.asset.asset_associations)
    },
    orderedAssetAssociations (assetAssociations) {
      if (this.randomizedAssetAssociations) {
        for (const i in assetAssociations) {
          assetAssociations[i].order = 1
        }
      } else {
        for (const j in assetAssociations) {
          assetAssociations[j].order = j
        }
      }
      return assetAssociations
    },
    save () {
      if (!this.saveDisabled) {
        this.saveDisabled = true
        if (this.asset.id) {
          this.update({ asset: this.asset, url: railsRoutes.api_path({ type: 'groupings' }) }).then(response => {
            this.edit(response.data.records[0])
            this.saveDisabled = false
            this.setEditingAssetDescription(false)
            this.resetErrors()
            this.$notify({ title: 'Grouping Saved', type: 'success' })
          }).catch(error => {
            this.saveDisabled = false
            if (error.response) {
              this.setErrors({ errors: error.response.data })
            }
            this.$notify({ title: 'Error saving Grouping', type: 'error' })
          })
        } else {
          // This should never happen
          this.saveDisabled = false
          this.$notify({ title: 'Error saving Grouping', type: 'error' })
        }
      }
    },
    overwriteLive () {
      this.$modal.show('save-modal')
    },
    cancel () {
      this.$modal.hide('save-modal')
    },
    overwrite () {
      if (this.canWriteToAsset && this.draftMode && this.canOverwriteLive) {
        axios.put(railsRoutes.api_path({ type: 'groupings', id: this.asset.id, action: 'overwrite_live' }), {
          grouping: this.asset
        }).then(response => {
          if (response.data.records.length > 0) {
            const record = response.data.records[0]
            this.edit(record)
          }
          this.$notify({ title: 'Live Layer Overwriten', type: 'success' })
          this.cancel()
        }).catch(error => {
          console.error('Error: ', error)
          this.$notify({ title: 'Error Overwriting Live Layer', type: 'error' })
          this.cancel()
        })
      }
    },
    toggleDraft (val) {
      if ((this.asset.live_grouping_id && val === false) || (this.asset.draft_grouping_id && val === true)) {
        this.draftMode = !this.draftMode
        let recordId = null
        if (this.draftMode) {
          recordId = this.asset.draft_grouping_id
        } else {
          recordId = this.asset.live_grouping_id
        }
        if (this.$router) {
          this.$router.push({ path: railsRoutes.cms_path({ type: 'groupings', id: recordId }) })
          this.resetAsset()
        }
      }
    },
    displayAssetTypeName (assetBType) {
      if (assetBType === 'V3Flashcard') {
        return 'Item'
      } else {
        return assetBType
      }
    }
  }
}
</script>

<style lang="scss">
.groupings-edit-container {
  .groupings-edit-content {
    display: flex;
    padding: 0px;
    flex-direction: column;
  }
  .main-container {
    width: 100%;
    padding: 0px 60px;
    margin-bottom: 2rem;
  }
  .grouping-name-section {
    input {
      margin-bottom: 0px;
    }
  }
  .grouping-label {
    font-weight: 700;
  }
  .grouping-column label {
    font-size: .75rem;
    text-transform: uppercase;
    letter-spacing: 0.03rem;
    font-weight: 700;
    margin-bottom: 8px;
  }
  .grouping-input {
    border: 2px solid $grey-3;
    border-radius: 4px;
  }
  .edit-label {
    font-size: .75rem;
  }
  .hr {
    display: flex;
    flex: 3;
  }
  .grouping-hr {
    width: 100%;
    margin-right: 1rem;
  }
  .dividing-section {
    margin: 2rem 0;
    display: flex;
    width: 100%;
  }
  .scenario-box-container {
    margin-bottom: 40px;
    border-bottom: 1px solid $space-gray-20;
    padding-bottom: 10px;
    .order-box {
      padding-bottom: 0px;
    }
  }
  .add-scenario-box {
    padding-bottom: 30px;
  }
  .order-switch {
    display: flex;
    .order-section {
      display: flex;
      flex: 1;
      &.randomize-order-button{
        flex: 2;
        align-self: center;
        margin: 0;
        padding: 0;
        .edit-label {
          margin-bottom: 0;
        }
      }
    }
  }
  .error-message {
    width: 100%;
  }
  .error-row {
    display: flex;
    align-content: center;
  }
  .add-asset-section {
    display: flex;
    width: 100%;
  }
  .center-actions {
    display: flex;
    align-items: flex-end;
    .hlt-select {
      margin: 0 30px;
      width: 300px;
    }
  }
  .asset-actions-section {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 1rem;
    .grouping-asset-button {
      background: $white-1;
      border: 2px solid $grey-4;
      color: $blue-2;
      font-size: 0.75rem;
      margin: 0 0 1rem 0;
      width: 155px;
    }
    .asset-side-list {
      margin-left: 0;
      text-align: left;
      .asset-association {
        color: $blue-1;
        font-size: .875rem;
        padding-top: 10px;
        &:hover {
          color: $grey-2;
          transition: 0.3s;
        }
      }
    }
    .asset-listing {
      list-style-type: none;
    }
  }
  .main-asset-section {
    flex: 5;
    margin-bottom: 1.5rem;
    margin-right: 1.5rem;
  }

  .association-section {
    padding: 3rem 6rem;
    border-top: 4px solid $grey-4;
    margin-bottom: 4rem;
  }
  .groupings-footer {
    width: 100%;
    padding: 1.75rem 8rem;
    background: $blue-5;
    min-height: 300px;
    display: flex;
    flex-direction: row;
    .grouping-column {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-right: 5rem;
    }
  }
  // For the overwrite modal
  .overwrite-warning {
    margin: 0 auto;
    font-weight: 700;
    font-size: 1.2rem;
  }

  // loading animation for loader
  .waiting-loader {
    margin: 2rem 0;
    border: 5px solid $white-1;
    border-top: 5px solid $blue-4;
    border-radius: 50%;
    width: 80px;
    height: 80px;
    animation: spin .75s linear infinite;
  }

  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}
</style>
