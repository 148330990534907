<template>
  <div class="asset-association-grouping-section">
    <h4 class="grouping-title-section">
      {{ assetData.name }}
    </h4>
    <div
      v-for="(item, index) in assetData.asset_associations.filter(x => !x.deleted)"
      :key="item.id"
      class="grouping-list"
    >
      {{ assetBTypeName(item.asset_b_type) }} {{ item.asset_b_id }}<span v-if="index != (assetData.asset_associations.length - 1)">,</span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['assetData'],
  methods: {
    assetBTypeName (assetType) {
      if (assetType === 'V3Flashcard') {
        return 'Item'
      } else {
        return assetType
      }
    }
  }
}

</script>

<style lang="scss">
.grouping-title-section {
  font-size: 1rem;
  font-weight: 700;
}
.grouping-list {
  display: inline;
}
</style>
