<template>
  <div>
    <draggable
      v-model="sortedAssetAssociations"
      handle=".order-box-drag"
      :group="sortedAssetAssociations"
      class="draggable"
    >
      <transition-group>
        <order-box
          v-for="(assetAssociation, index) in sortedAssetAssociations"
          :key="'asset-association-' + index"
          :order-object="assetAssociation"
          :order-object-type="displayAssetTypeName(assetAssociation.asset_b_type)"
          :order-object-id="assetAssociation.asset_b_id"
          :anchor-key="anchorPrefix + index"
          :index="index"
          :draft-mode="draftMode"
          :box-styles="boxStyles"
          group-name="sortedAssetAssociations"
          :delete-modal-name="&quot;delete-section-&quot; + sectionId + &quot;-order-box-modal&quot;"
          :open-asset-link="openAssetLink(assetAssociation)"
        >
          <template v-slot:headerTitle>
            {{ displayAssetTypeName(assetAssociation.asset_b_type) }} {{ assetAssociation.asset_b_id }}
          </template>

          <template v-slot:content>
            &nbsp; <!-- This has to be here or the components dont get inserted -->
            <item
              v-if="assetAssociation.asset_b_type === 'V3Flashcard'"
              :asset-data="assetAssociation.asset_b_data"
            />
            <grouping
              v-if="assetAssociation.asset_b_type === 'Grouping'"
              :asset-data="assetAssociation.asset_b_data"
            />
            <topic
              v-if="assetAssociation.asset_b_type === 'Topic'"
              :asset-data="assetAssociation.asset_b_data"
            />
            <attachment
              v-if="assetAssociation.asset_b_type === 'Attachment'"
              :asset-data="assetAssociation.asset_b_data"
            />
          </template>
        </order-box>
      </transition-group>
    </draggable>
    <delete-order-box-modal
      :delete-modal-name="&quot;delete-section-&quot; + sectionId + &quot;-order-box-modal&quot;"
      @orderBoxDeletion="deleteBox"
    />
  </div>
</template>

<script>
import Draggable from 'vuedraggable'
import Item from '../../shared/asset_associations/Item'
import Grouping from '../../shared/asset_associations/Grouping'
import Topic from '../../shared/asset_associations/Topic'
import Attachment from '../../shared/asset_associations/Attachment'
import OrderBox from '../../../shared/OrderBox'
import DeleteOrderBoxModal from '../../../shared/DeleteOrderBoxModal'
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    Draggable,
    Item,
    Grouping,
    Topic,
    Attachment,
    OrderBox,
    DeleteOrderBoxModal
  },
  props: {
    assetAssociations: {
      type: Array,
      required: true
    },
    draftMode: {
      type: Boolean
    },
    anchorPrefix: {
      type: String
    },
    sectionId: {
      type: Number
    },
    assetType: {
      type: String
    }
  },
  data () {
    return {
      boxStyles: {
        header: [],
        content: ['height-restriction']
      }
    }
  },
  computed: {
    ...mapGetters([
      'asset'
    ]),
    sortedAssetAssociations: {
      get () {
        if (!this.assetAssociations.length) return
        const arr = this.assetType === 'learning_module'
          ? this.asset.module_sections.filter(m => m.id === this.sectionId)[0].asset_associations
          : this.assetAssociations
        return this._.sortBy(arr, 'order')
      },
      set (value) {
        if (this.assetType === 'learning_module') {
          let section = this.asset.module_sections.filter(m => m.id === this.sectionId)[0]
          section.asset_associations = value.map((item, index) => {
            return {
              ...item,
              order: index + 1
            }
          })
          this.setAsset({ asset: this.asset })
        } else {
          let order = 1
          value.forEach(v => {
            let index = this.asset.asset_associations.indexOf(v)
            this.asset.asset_associations[index].order = order
            order += 1
          })

          this.setAsset({ asset: this.asset })
        }
      }
    }
  },
  methods: {
    ...mapMutations([
      'setAsset'
    ]),
    deleteBox (params) {
      let localAssociations = this.sortedAssetAssociations
      localAssociations[params.index].deleted = true
      this.sortedAssetAssociations = localAssociations
    },
    displayAssetTypeName (assetBType) {
      if (assetBType === 'V3Flashcard') {
        return 'Item'
      } else {
        return assetBType
      }
    },
    routeAssetTypeName (assetBType) {
      if (assetBType === 'V3Flashcard') {
        return 'item'
      } else if (assetBType === 'Grouping') {
        return 'grouping'
      } else if (assetBType === 'Topic') {
        return 'topic'
      } else if (assetBType === 'Attachment') {
        return 'attachment'
      } else {
        return assetBType
      }
    },
    openAssetLink (assetAssociation) {
      return { name: this.routeAssetTypeName(assetAssociation.asset_b_type), params: { id: assetAssociation.asset_b_id } }
    }
  }
}
</script>

<style lang="scss" scoped>
.section-asset-box {
  background: $white-2;
  margin-bottom: 20px;
  .section-asset-label {
    font-size: 12.8px;
    letter-spacing: .5px;
    margin: 0;
  }
  .header {
    padding-left: 10px;
    display: flex;
    border: 1px solid $space-gray;
    border-bottom: 0;
    overflow: hidden;
    padding-left: 10px;
    min-height: 30px;
    align-items: center;
    justify-content: space-around;
    .left {
      display: flex;
      flex: 4;
    }
    .right {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .action-icons {
        text-align: center;
        padding: 5px;
        width: 40px;
        border-left: 1px solid $space-gray;
        cursor: pointer;
      }
    }
  }
  .asset-content {
    padding: 16px 24px 10px;
    border: 1px solid $space-gray;
    background: $white-2;
    min-height: 100px;
    max-height: 180px;
    overflow: hidden;
    position: relative;
      .fade-content-feature {
        position: absolute;
        height: 50%;
        width: 100%;
        bottom: 0;
        left: 0;
        background: -moz-linear-gradient(top,  rgba(247,247,247,0) 0%, rgba(247,247,247,1) 80%, rgba(247,247,247,1) 100%);
        background: -webkit-linear-gradient(top,  rgba(247,247,247,0) 0%,rgba(247,247,247,1) 80%,rgba(247,247,247,1) 100%);
        background: linear-gradient(to bottom,  rgba(247,247,247,0) 0%,rgba(247,247,247,1) 80%,rgba(247,247,247,1) 100%);
        border-radius: 0 0 4px 4px;
      }
  }
}
.add-asset-row, .loading-asset-section {
  display: flex;
  flex-direction: row;
  align-content: center;
  justify-content: space-around;
  align-items: stretch;
  width: 100%;
  padding: .75rem 5rem;
  .asset-select {
    flex-grow: 1;
    margin-right: 1.5rem;
  }
  .asset-input {
    flex-grow: 1;
    margin-right: 1.5rem;
    padding-bottom: 10px;
  }
  .asset-submit {
    width: auto;
    margin-right: 1.5rem;
    padding-top: 32px;
    button {
      margin-bottom: 1.25rem;
      &:disabled {
        background: $green-1;
      }
    }
  }
}
</style>
