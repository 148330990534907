<template>
  <div class="asset-association-topic-section">
    <h4 class="topic-title-section">
      {{ assetData.title }}
    </h4>
    <div
      class="topic-content-section"
      v-html="assetData.content"
    />
  </div>
</template>

<script>
export default {
  props: {
    assetData: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.topic-title-section {
  font-size: 1rem;
  font-weight: 700;
}
</style>
