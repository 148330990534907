<template>
  <div class="modules-edit-container">
    <div class="module-edit-content">
      <div class="asset-description-container">
        <div class="asset-description-inner">
          <asset-description :asset="asset" :editing="editingAssetDescription" @set-editing="setEditingAssetDescription"/>
        </div>
      </div>

      <div class="main-container">
        <h4 class="hlt-header h4 weight-700 module-text-header">
          Module ID: #{{ asset.id }}
        </h4>
        <div class="main-section">
          <div class="left-edit">
            <div class="main-module-container">
              <div class="module-name-section">
                <div class="left-section">
                  <label class="module-label weight-600">Module Name</label>
                  <input
                    v-model="asset.title"
                    class="module-input hlt-input module-name-input"
                    type="text"
                    required
                    :class="{'error': errors['name'] }"
                    placeholder="Write module title"
                    :disabled="!draftMode"
                  >
                  <error-message
                    v-if="errors[&quot;title&quot;]"
                    message="Title can't be blank"
                  />
                  <span
                    v-if="!errors['title']"
                    class="helper-text required-title-text"
                  >Required</span>
                </div>
                <div class="right-section">
                  <label class="module-label weight-600">Featured Attachment</label>
                  <input
                    v-model="asset.featured_attachment_id"
                    class="module-input hlt-input"
                    type="text"
                    :class="{'error': errors['name'] }"
                    placeholder="Attachment ID"
                    :disabled="!draftMode"
                  >
                </div>
              </div>
              <div
                class="module-container"
                @click="e => moduleToggle(e, 'editingDescription', 'module-description')"
              >
                <div
                  v-if="!editing['editingDescription']"
                  class="module-edit-html"
                >
                  <label
                    class="container-label weight-600"
                    for="description"
                  >Module Description</label>
                  <div
                    :class="[{ 'empty-content': emptyContent(asset.description), 'disabled': !draftMode }, 'container-textarea']"
                    name="description"
                    v-html="asset.description || 'Write module description'"
                  />
                </div>

                <div
                  v-if="editing['editingDescription']"
                  class="module-edit-active"
                >
                  <label
                    class="container-label weight-600"
                    for="description"
                  >Module Description</label>
                  <tinymce-editor
                    ref="module-description"
                    v-model="asset.description"
                    v-on-clickaway="e => moduleToggle(e, 'editingDescription', 'module-description')"
                    class="module-tinymce"
                    :init="tinymceConfig({plugins: ['code', 'autoresize', 'table', 'lists','image'], forced_root_block : ''}, tinymceFullConfig)"
                    name="description"
                    @onInit="setCaretPosition"
                  />
                </div>
              </div>
              <div
                class="module-container"
                @click="e => moduleToggle(e, 'editingInformation', 'module-information')"
              >
                <div
                  v-if="!editing['editingInformation']"
                  class="module-edit-html"
                >
                  <label
                    class="container-label weight-600"
                    for="additional"
                  >Module Additional Information</label>
                  <div
                    :class="[{ 'empty-content': emptyContent(asset.additional_information_html), 'disabled': !draftMode }, 'container-textarea']"
                    name="additional"
                    v-html="asset.additional_information_html || 'Add additional text'"
                  />
                </div>

                <div
                  v-if="editing['editingInformation']"
                  class="module-edit-active"
                >
                  <label
                    class="container-label weight-600"
                    for="additional"
                  >Module Additional Information</label>
                  <tinymce-editor
                    ref="module-information"
                    v-model="asset.additional_information_html"
                    v-on-clickaway="e => moduleToggle(e, 'editingInformation', 'module-information')"
                    class="module-tinymce"
                    :init="tinymceConfig({plugins: ['code', 'autoresize', 'table', 'lists','image'], forced_root_block : ''}, tinymceFullConfig)"
                    name="additional"
                    @onInit="setCaretPosition"
                  />
                </div>
              </div>
              <div
                class="module-container"
                @click="e => moduleToggle(e, 'editingCompletion', 'module-information')"
              >
                <div
                  v-if="!editing['editingCompletion']"
                  class="module-edit-html"
                >
                  <label
                    class="container-label weight-600"
                    for="completion"
                  >Module Completion Text</label>
                  <div
                    :class="[{ 'empty-content': emptyContent(asset.completion_page_html), 'disabled': !draftMode }, 'container-textarea']"
                    name="completion"
                    v-html="asset.completion_page_html || 'Write module description'"
                  />
                </div>

                <div
                  v-if="editing['editingCompletion']"
                  class="module-edit-active"
                >
                  <label
                    class="container-label weight-600"
                    for="description"
                  >Module Completion Text</label>
                  <tinymce-editor
                    ref="module-completion"
                    v-model="asset.completion_page_html"
                    v-on-clickaway="e => moduleToggle(e, 'editingCompletion', 'module-completion')"
                    class="module-tinymce"
                    :init="tinymceConfig({plugins: ['code', 'autoresize', 'table', 'lists','image'], forced_root_block : ''}, tinymceFullConfig)"
                    name="title"
                    @onInit="setCaretPosition"
                  />
                </div>
              </div>
            </div>
            <div class="main-section-container">
              <h5 class="hlt-header h5 weight-600 module-text-subheader">
                Module Sections
              </h5>
              <p
                v-if="!asset.module_sections"
                class="small-text weight-600"
              >
                Get started by adding a section
              </p>
              <div
                v-if="asset && asset.module_sections"
                class="active-module-sections"
              >
                <div
                  v-for="(section, index) in sortedModuleSections"
                  :key="'sortedModuleSections-' + section.id + '-' + index"
                >
                  <module-section
                    :draft-mode="draftMode"
                    :section="section"
                    :index="index"
                    :asset-associations="assetAssociations"
                    :sections-length="sortedActiveSections.length"
                    @saveDisabled="disableSaving"
                    @moveModuleSection="moveSection"
                  />
                </div>
              </div>
              <button
                v-if="draftMode"
                class="hlt-button secondary"
                @click="addNewSection"
              >
                Add Section
              </button>
            </div>

            <delete-order-box-modal
              delete-modal-name="delete-section-modal"
              @orderBoxDeletion="deleteBox"
            />
          </div>
          <div class="right-edit">
            <span class="hlt-header h5 weight-600 outline-header">Outline</span>
            <span
              v-if="!sortedModuleSections"
              class="weight-600 small-text"
            >Get started by adding a section</span>
            <ul class="outline-side-list">
              <li
                v-for="(section, index) in sortedActiveSections"
                :key="'sortedActiveSections-' + section.id"
                class="outline-listing"
              >
                <a
                  v-smooth-scroll="{offset: -150, updateHistory: false}"
                  :href="'#' + 'section' + '-' + index"
                  class="outline-link"
                >
                  <span class="outline-title weight-600">{{ section.title | truncate(20) }} - {{ section.id }}</span>
                </a>

                <div
                  v-for="(asset, i) in sortedAssetAssociations(section)"
                  :key="'sortedAssetAssociations-' + section.id + '-' + asset.id"
                >
                  <a
                    v-if="!asset.deleted"
                    v-smooth-scroll="{offset: -150, updateHistory: false}"
                    :href="'#section-' + index + '-' + i"
                    class="outline-link"
                  >
                    <span class="outline-asset-title">{{ displayAssetTypeName(asset.asset_b_type) }} - {{ asset.asset_b_id }}</span>
                  </a>
                </div>
              </li>
            </ul>
            <button
              v-if="draftMode"
              class="hlt-button secondary outline-add-button"
              @click="addNewSection"
            >
              Add Section
            </button>
          </div>
        </div>
      </div>
      <div class="association-section">
        <product-association
          v-if="currentUser && currentUser.permissions.can_read_learning_modules_product_associations && asset.id && draftMode"
          :asset="asset"
          class="product-association-container"
        />
      </div>

      <metadata>
        <template v-slot:left>
          <div class="module-section section">
            <label
              for="module-type"
              class="module-label"
            >Module Type</label>
            <select
              v-model="asset.learning_module_type"
              name="module-type"
              class="hlt-select"
            >
              <option value="Standard">
                Standard
              </option>
            </select>
          </div>
          <div class="module-section section">
            <label>Completion Time (minutes)</label>
            <input
              v-model="asset.completion_time"
              type="number"
              :disabled="!canWriteToAsset"
              class="hlt-input"
            >
          </div>
        </template>
        <template v-slot:right>
        </template>
      </metadata>

      <save-modal @save="overwrite" :asset="asset" v-if="asset.id != undefined" />

    </div>
    <div class="draft-live-footer">
      <edit-footer>
        <template v-slot:left>
          <div class="left">
            <button
              class="hlt-button default draft"
              :class="{ active : draftMode }"
              @click="toggleDraft(true)"
            >
              Draft
            </button>
            <button
              class="hlt-button default live"
              :class="{ active : !draftMode }"
              @click="toggleDraft(false)"
            >
              Live
            </button>
          </div>
        </template>
        <template v-slot:center>
          <div class="center">
            <select class="hlt-select">
              <option>Select State</option>
              <option>New</option>
              <option>Review</option>
              <option>Edit</option>
              <option>Preflight</option>
              <option>Approved</option>
            </select>
            <button
              v-if="!saveDisabled"
              class="hlt-button primary save-button"
              :class="{ grey: (!draftMode || !canWriteToAsset) }"
              @click="canWriteToAsset && draftMode && save()"
            >
              Save Changes
            </button>
            <button
              v-if="saveDisabled"
              class="hlt-button primary disabled save-button"
            >
              <spinner /> Save Changes
            </button>
          </div>
        </template>
        <template v-slot:right>
          <div class="right">
            <button
              class="hlt-button secondary overwrite"
              :class="{ grey: !draftMode || !canOverwriteLive }"
              @click="canWriteToAsset && canOverwriteLive && draftMode && overwriteLive()"
            >
              Overwrite Live
            </button>
          </div>
        </template>
      </edit-footer>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'
import Spinner from '../shared/Spinner'
import railsRoutes from '../../../store/rails-routes'
import ProductAssociation from '../shared/ProductAssociation'
import TinymceEditor from '@tinymce/tinymce-vue'
import tinymce from '../../../mixins/tinymce'
import { mixin as clickaway } from 'vue-clickaway2'
import axios from 'axios'
import ModuleSection from './ModuleSection'
import EditFooter from '../shared/page_components/EditFooter'
import randomize from '../../../mixins/randomize-choices'
import DeleteOrderBoxModal from '../../shared/DeleteOrderBoxModal'
import Metadata from '../shared/Metadata'
import AssetDescription from '../shared/AssetDescription'
import AssetDescriptionMixin from '../../../mixins/asset-description'
import SaveModal from '../shared/SaveModal.vue'

export default {
  name: 'Edit',
  components: {
    Spinner,
    ProductAssociation,
    TinymceEditor,
    ModuleSection,
    EditFooter,
    DeleteOrderBoxModal,
    Metadata,
    AssetDescription,
    SaveModal
  },
  mixins: [
    clickaway,
    tinymce,
    randomize,
    AssetDescriptionMixin
  ],
  props: ['learningModuleId'],
  data () {
    return {
      draftMode: true,
      editing: {
        editingDescription: false,
        editingInformation: false,
        editingCompletion: false
      },
      saveDisabled: false,
      showAddSection: false
    }
  },
  computed: {
    ...mapGetters([
      'asset',
      'deleteAsset',
      'currentUser',
      'canWriteToAsset',
      'errors'
    ]),
    sortedModuleSections () {
      return this._.sortBy(this.asset.module_sections, ['order'])
    },
    sortedActiveSections () {
      return this.sortedModuleSections.filter(x => !x.deleted)
    },
    canOverwriteLive () {
      return this.currentUser.permissions.can_overwrite_live_learning_modules
    },
    assetAssociations: {
      get () {
        if (this.asset.asset_associations) {
          return this._.sortBy(this.asset.asset_associations, ['order']).filter(s => !s.deleted)
        } else {
          return []
        }
      },
      set (value) {
        this.asset.asset_associations = this.orderArray(value, 'order')
      }
    }
  },
  created () {
    this.setAssetType({ assetType: 'learning_module' })
    this.apiLearningModulesById({ learningModuleId: this.learningModuleId }).then(response => {
      var record = response.data.records[0]
      this.edit(record)
      this.draftMode = !this.asset.live_layer
    })
  },
  methods: {
    ...mapActions([
      'edit',
      'delete',
      'update',
      'apiLearningModulesById'
    ]),
    ...mapMutations([
      'setAsset',
      'setAssetType',
      'setDeleteAsset',
      'setDeletableAsset',
      'resetDeletableAsset',
      'setErrors',
      'resetErrors',
      'setOrderBoxReordering',
      'resetAsset'
    ]),
    moveSection ({ index, section, direction }) {
      const incrementedSection = item => {
        item.order += 1
      }

      const decrementedSection = item => {
        item.order -= 1
      }

      let item = this.sortedModuleSections[index]

      if (direction === 'up') {
        let incrementedItem = this.sortedActiveSections[(item.order - 1) - 1]
        incrementedSection(incrementedItem)
        decrementedSection(item)
      } else {
        let decrementedItem = this.sortedActiveSections[(item.order - 1) + 1]
        incrementedSection(item)
        decrementedSection(decrementedItem)
      }

      let obj = {
        ...this.asset,
        module_sections: this.sortedModuleSections
      }
      this.setAsset({ asset: obj })
      this.setOrderBoxReordering(false)
    },
    deleteBox (params) {
      let localSections = this.sortedModuleSections
      localSections[params.index].deleted = true
      localSections[params.index].order = 9999999

      // Adjust orders of everything after this section
      for (let x = params.index + 1; x < localSections.length; x++) {
        localSections[x].order -= 1
      }

      this.asset.module_sections = localSections
    },
    disableSaving (value) {
      this.saveDisabled = value
    },
    save () {
      if (!this.saveDisabled) {
        this.saveDisabled = true
        if (this.asset.id) {
          this.update({ asset: this.asset, url: railsRoutes.api_path({ type: 'learning_modules' }) }).then(response => {
            this.edit(response.data.records[0])
            this.saveDisabled = false
            this.resetErrors()
            this.setEditingAssetDescription(false)
            this.$notify({ title: 'Module Saved', type: 'success' })
          }).catch(error => {
            this.saveDisabled = false
            if (error.response) {
              this.setErrors({ errors: error.response.data })
            }
            this.$notify({ title: 'Error saving Module', type: 'error' })
          })
        } else {
          // This should never happen
          this.saveDisabled = false
          this.$notify({ title: 'Error saving Module', type: 'error' })
        }
      }
    },
    addNewSection () {
      let moduleId = this.asset.id
      let url = railsRoutes.api_path({ type: 'module_sections' })
      axios.post(url, { learning_module_id: moduleId })
        .then(response => {
          let newSection = response.data.records[0]
          // this.asset.module_sections.push(newSection)
          this.setAsset({ asset: {
            ...this.asset,
            module_sections: [...this.asset.module_sections, newSection]
          } })
          this.showAddSection = true
        })
        .catch(err => console.error('Error saving section: ', err))
    },
    displayAssetTypeName (assetBType) {
      if (assetBType === 'V3Flashcard') {
        return 'Item'
      } else {
        return assetBType
      }
    },
    moduleToggle (event, prop, reference) {
      if (this.draftMode) {
        if (!event) return
        if (event.target.className.includes('mce') || event.target.id.includes('mce')) {
          return
        }
        Object.keys(this.editing).forEach(key => {
          this.editing[key] = prop == key ? !this.editing[prop] : false
        })
        this.setCharPosition(event, reference)
      }
    },
    toggleDraft (val) {
      if ((this.asset.live_learning_module_id && val === false) || (this.asset.draft_learning_module_id && val === true)) {
        this.draftMode = !this.draftMode
        var recordId = null
        if (this.draftMode) {
          recordId = this.asset.draft_learning_module_id
        } else {
          recordId = this.asset.live_learning_module_id
        }
        if (this.$router) {
          this.$router.push({ path: `/learning_modules/${recordId}/edit` })
          this.resetAsset()
        }
      }
    },
    overwrite () {
      if (this.canWriteToAsset && this.draftMode && this.canOverwriteLive) {
        axios.put(railsRoutes.api_path({ type: 'learning_modules', id: this.asset.id, action: 'overwrite_live' }), {
          learning_module: this.asset
        }).then(response => {
          if (response.data.records.length > 0) {
            let record = response.data.records[0]
            this.edit(record)
          }
          this.$notify({ title: 'Live Layer Overwriten', type: 'success' })
          if (this.$modal) {
            this.$modal.hide('save-modal')
          }
        }).catch(error => {
          console.error('Error: ', error)
          this.$notify({ title: 'Error Overwriting Live Layer', type: 'error' })
          if (this.$modal) {
            this.$modal.hide('save-modal')
          }
        })
      }
    },
    overwriteLive () {
      if (this.$modal) {
        this.$modal.show('save-modal')
      }
    },
    cancel () {
      if (this.$modal) {
        this.$modal.hide('save-modal')
      }
    },
    emptyContent (content) {
      return content === null || content === ''
    },
    sortedAssetAssociations (section) {
      return this._.sortBy(section.asset_associations, 'order')
    }
  }

}
</script>

<style lang="scss">
.modules-edit-container {
  .asset-description-container {
    display: flex;
    justify-content: center;
    align-items: center;
    .asset-description-inner {
      width: 100%;
    }
  }

  label {
    margin-bottom: 4px;
  }
  .small-text {
    font-size: 14px;
    color: $space-black-50;
  }
  .module-input {
    margin: 0 0 5px 0;
  }
  .module-name-input {
    margin: 0;
  }
  .helper-text {
    color: $space-black-50;
    font-size: .75rem;
  }
  .main-module-container {
    padding-bottom: 20px;
  }
  .outline-link {
    color: $space-black;
    transition: .3s;
    &:hover {
      color: $space-gray;
      cursor: pointer;
    }
  }
  .container-textarea {
    font-size: 14px;
    padding: 10px;
    height: auto;
    min-height: 150px;
    border: 1px solid $space-gray;
    background: $white-2;
    border-radius: 4px;
    &:hover {
      border: 1px solid $sky-blue;
    }
    &.disabled {
      background: $grey-4;
    }
  }
  .empty-content {
    color: $space-gray;
  }
  .module-edit-content{
    display: flex;
    padding: 0px;
    justify-content: space-between;
    flex-direction: column;
  }
  .main-container {
    width: 100%;
    padding: 0px 60px;
    margin-bottom: 2rem;
    .main-section {
      display: flex;
      justify-content: space-between;
      .left-edit {
        flex: 4;
        padding-right: 44px;
      }
      .right-edit {
        flex: 1;
      }
    }
  }
  .main-section-container {
    padding-bottom: 100px;
  }
  .module-name-section {
    display: flex;
    justify-content: space-between;
    margin-bottom: 24px;
    .left-section {
      flex: 1;
      padding-right: 24px;
    }
  }
  .module-container {
    margin-bottom: 24px;
  }

  .asset-b-input {
    margin-bottom: 4px;
  }
  .section-area {
    padding-bottom: 24px;
  }
  .module-edit-active .mce-tinymce {
    border: 1px solid $sky-blue;
    border-radius: 4px;
  }
  .outline-title {
    font-size: 12.8px;
  }
  .outline-side-list {
    margin: 0;
    margin-bottom: 16px;
  }
  .outline-listing {
    list-style: none;
  }
  .outline-asset-title {
    font-size: 12.8px;
  }
  .outline-header {
    display: block;
    margin: 0;
    margin-bottom: 8px;
  }
  .association-section {
    padding: 1.75rem 12rem;
    border-top: 4px solid $grey-4;
    margin-bottom: 4rem;
  }
  .modules-footer {
    width: 100%;
    padding: 1.75rem 8rem;
    background: $blue-5;
    min-height: 300px;
    display: flex;
    flex-direction: row;
    .module-column {
      display: flex;
      flex-direction: column;
      flex: 1;
      margin-right: 5rem;
    }
    .module-select {
      width: 50%;
    }
  }
  .draft-live-footer {
    .hlt-select {
      margin: 0 30px;
      width: 300px;
    }
  }
  .module-text-header {
    margin-bottom: 13px;
  }
  .module-text-subheader {
    margin: 0;
    padding-bottom: 8px;
  }
}
</style>
