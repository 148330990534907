<template>
  <div>
    <top-nav />
    <div class="edit-template-container">
      <topic-edit
        v-if="this.$route.name == 'topic'"
        :topic-id="this.$route.params.id"
      />
      <attachment-edit
        v-if="this.$route.name == 'attachment'"
        :attachment-id="this.$route.params.id"
      />
      <item-edit
        v-if="this.$route.name == 'item'"
        :item-id="this.$route.params.id"
      />
      <grouping-edit
        v-if="this.$route.name == 'grouping'"
        :grouping-id="this.$route.params.id"
      />
      <learning-modules-edit
        v-if="this.$route.name == 'learning_module'"
        :learning-module-id="this.$route.params.id"
      />
      <div class="asset-discussions-section">
        <asset-discussions />
      </div>
    </div>
  </div>
</template>

<script>
import TopNav from './TopNav.vue'
import TopicEdit from '../topics/Edit.vue'
import AttachmentEdit from '../attachments/Edit.vue'
import ItemEdit from '../items/Edit.vue'
import GroupingEdit from '../groupings/Edit.vue'
import LearningModulesEdit from '../learning_modules/Edit'
import AssetDiscussions from '../shared/AssetDiscussions'

export default {
  components: {
    TopNav,
    TopicEdit,
    AttachmentEdit,
    ItemEdit,
    GroupingEdit,
    LearningModulesEdit,
    AssetDiscussions
  }
}
</script>
<style lang="scss" scoped>
.edit-template-container {
  position: relative;
  height: 100%;
}
</style>
