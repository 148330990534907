<template>
  <div
    class="asset-association-item-content"
    v-html="assetData.question"
  />
</template>

<script>
export default {
  props: ['assetData']
}
</script>

<style lang="scss">
.asset-association-item-content {
  overflow: hidden;
}
</style>
