<template>
  <div
    v-if="assetData"
    class="asset-association-attachment-section"
  >
    <div class="attachment-flex-row">
      <div class="flex-image">
        <img
          class="image-preview"
          :src="assetData.image"
          alt=""
        >
      </div>
      <div class="flex-content">
        <h4 class="attachment-name-title">
          {{ assetData.name }}
        </h4>
        <p class="attachment-type">
          {{ assetData.type }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['assetData']
}
</script>

<style lang="scss">
.attachment-flex-row {
  display: flex;
  flex-direction: row;
  align-content: left;
  justify-content: space-around;
  align-items: flex-start;
  width: 100%;
  .flex-image {
    flex: 1;
    margin: 0 1rem;
    .image-preview {
      height: 120px;
      max-width: 100%;
    }
  }
  .flex-content {
    flex: 3;
    .attachment-name-title {
      font-size: 1rem;
      font-weight: 700;
    }
  }
}

</style>
